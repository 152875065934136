import { Component, OnInit, HostListener } from '@angular/core';

@Component({
  selector: 'app-keypages',
  templateUrl: './keypages.component.html',
  styleUrls: ['./keypages.component.scss']
})
export class KeypagesComponent implements OnInit {
  isSticky: boolean = false;
  constructor() { }

  ngOnInit(): void {
  }
  @HostListener('window:scroll', ['$event'])
  checkScroll() {
    this.isSticky = window.pageYOffset >= 110;
  }
}
