<div class="top-nav-inner">
  <div class="top-nav-inner-brand">
    <span aria-label="HDZB LOGO" [inlineSVG]="'../../../../assets/img/cubee-logo.svg'"></span>
  </div>
  <div class="spacer"></div>
  <div class="top-nav-inner-menu">
    <nav mat-tab-nav-bar>
      <a mat-tab-link [routerLinkActive]="['mat-tab-label-active']" [routerLink]="links[0].link" [attr.target]="links[0].target"> {{ links[0].name }} </a>
      <!-- <a mat-tab-link [routerLinkActive]="['mat-tab-label-active']" [routerLink]="links[1].link" [attr.target]="links[1].target"> {{ links[1].name }} </a> -->
      <a mat-tab-link [routerLinkActive]="['mat-tab-label-active']" [routerLink]="links[2].link" [attr.target]="links[2].target"> {{ links[2].name }} </a>
      <!-- <a mat-tab-link [attr.target]="links[1].target" [href]="links[1].link"> {{ links[1].name }} </a> -->
    </nav>
  </div>
  <button color="white" mat-icon-button [matMenuTriggerFor]="menu" aria-label="MENU" class="desktop">
    <mat-icon>menu</mat-icon>
  </button>
  <mat-menu #menu="matMenu">
    <button mat-menu-item *ngFor="let item of links" [routerLink]="item.link">
      <span> {{ item.name }} </span>
    </button>
  </mat-menu>
</div>
