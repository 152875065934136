
  <mat-card class="section-features-box">
    <div class="section-features-icon-container">
      <ng-content select=".section-features-icon-overlay"></ng-content>
      <ng-content select=".section-features-icon"></ng-content>
    </div>
    <ng-content select=".section-features-title"></ng-content>
    <ng-content select=".section-features-desc"></ng-content>
  </mat-card>

