import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Client, Careers } from '../helper/data'

@Injectable()
export class ClientServices {
    constructor(private http: HttpClient) { }

    getClient() {
        return this.http.get<any>('assets/data/client.json')
            .toPromise()
            .then(res => <Client[]>res.data)
            .then(data => { return data; });
    }
}

@Injectable()
export class CareersServices {
    constructor(private http: HttpClient) { }

    getCareers() {
        return this.http.get('assets/data/careers.json')
    }
}
