import { Component, OnInit, HostBinding } from '@angular/core';

@Component({
  selector: 'app-keypages-header',
  templateUrl: './keypages-header.component.html',
  styleUrls: ['./keypages-header.component.scss']
})
export class KeypagesHeaderComponent implements OnInit {
  @HostBinding('class.app-keypages-header') isHostClass: boolean = true;

  constructor() { }

  ngOnInit(): void {
  }

}
