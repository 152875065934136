import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'top-nav',
  templateUrl: './top-nav.component.html',
  styleUrls: ['./top-nav.component.scss']
})
export class TopNavComponent implements OnInit {

  links = [
    {
      id: 1,
      name: "HOME",
      link: "/home",
      target: ''
    },
    {
      id: 3,
      name: "AIBALL",
      link: "/aiball/demo",
      target: ''
    },
    {
      id: 2,
      name: "CAREERS",
      link: "/careers",
      target: ''
    }
  ];
  activeLink = this.links[0];

  constructor(
    public router: Router
  ) {

  }

  ngOnInit(): void {
  }

  addLink() {
    // this.links.push(`Link ${this.links.length + 1}`);
  }

}
