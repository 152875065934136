import { NgModule } from '@angular/core';

import { InlineSVGModule } from 'ng-inline-svg';
import { AnimateModule } from '../library/animation/animate.module'
import { FlexLayoutModule } from '@angular/flex-layout';
import { SwiperModule, SWIPER_CONFIG, SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TitleComponent } from '../components/modules/title/title.component';
import { FeaturesComponent } from '../components/modules/features/features.component';
import { ContentCommon } from '../components/modules/content/content-common/content-common.component';
import { ContentBodyComponent } from '../components/modules/content/content-body/content-body.component';
import { ContentImageComponent } from '../components/modules/content/content-image/content-image.component';
import { ContentCenterComponent } from '../components/modules/section/section.component';
import { KeypagesHeaderComponent } from '../components/modules/keypages/keypages-header/keypages-header.component';
import { LottieModule } from 'ngx-lottie';
import player from 'lottie-web';
import { MaterialModule } from './material.module';
import { MatFileUploadModule } from 'angular-material-fileupload';

import { ClientServices, CareersServices } from '../services/data.service'

const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
  direction: 'horizontal',
  slidesPerView: 'auto'
};

export function playerFactory() {
  return player;
}

@NgModule({
  declarations: [
    TitleComponent,
    FeaturesComponent,
    ContentCommon,
    ContentBodyComponent,
    ContentImageComponent,
    ContentCenterComponent,
    KeypagesHeaderComponent
  ],
  imports: [
    InlineSVGModule,
    AnimateModule,
    FlexLayoutModule,
    SwiperModule,
    TranslateModule,
    MaterialModule,
    LottieModule.forRoot({ player: playerFactory }),
    MatFileUploadModule
  ],
  exports: [
    InlineSVGModule,
    AnimateModule,
    FlexLayoutModule,
    SwiperModule,
    TitleComponent,
    FeaturesComponent,
    TranslateModule,
    ContentCommon,
    MaterialModule,
    ContentBodyComponent,
    ContentImageComponent,
    LottieModule,
    ContentCenterComponent,
    KeypagesHeaderComponent,
    MatFileUploadModule
  ],
  providers: [
    ClientServices,
    CareersServices,
    {
      provide: SWIPER_CONFIG,
      useValue: DEFAULT_SWIPER_CONFIG
    }
  ],
})
export class SharedModule { }
