import { Component, OnInit } from '@angular/core';
import { AnimationOptions } from 'ngx-lottie';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  options: AnimationOptions = {
    path: '../../../assets/animation/footer-wave.json',
    loop: 1
  };

  constructor() { }

  ngOnInit(): void {
  }

}
