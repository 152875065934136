import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { RouterModule } from '@angular/router';
import { MaterialModule } from './material.module';
import { HttpClientModule } from '@angular/common/http';

// Main Content
import { ContentComponent } from './content/content.component';
import { KeypagesComponent } from './keypages/keypages.component';
// Header
import { TopNavComponent } from '../components/header/top-nav/top-nav.component'
import { FooterComponent } from '../components/footer/footer.component';

import { SharedModule } from '../layout/shared.module'


@NgModule({
  declarations: [
    ContentComponent,
    KeypagesComponent,
    TopNavComponent,
    FooterComponent
  ],
  imports: [
    CommonModule,
    AppRoutingModule,
    RouterModule,
    MaterialModule,
    HttpClientModule,
    SharedModule
  ]
})
export class LayoutModule { }
