import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ContentComponent } from './layout/content/content.component'
import { KeypagesComponent } from './layout/keypages/keypages.component'
import { JobsPage } from './pages/career/jobs/jobs.page';

const routes: Routes = [
  {
    path: 'home',
    component: ContentComponent,
    loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule),
  },
  // {
  //   path: 'about',
  //   component: KeypagesComponent,
  //   loadChildren: () => import('./pages/about/about.module').then(m => m.AboutModule),
  // },
  {
    path: 'group',
    component: KeypagesComponent,
    loadChildren: () => import('./pages/group/group.module').then(m => m.GroupPageModule),
  },
  {
    path: 'aiball/demo',
    component: KeypagesComponent,
    loadChildren: () => import('./pages/aiball/aiball.module').then(m => m.AiballPageModule),
  },
  {
    path: 'careers',
    component: KeypagesComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./pages/career/career.module').then(m => m.CareerModule),
      },
      {
        path: 'jobs/:id',
        loadChildren: () => import('./pages/career/jobs/jobs.module').then(m => m.JobsModule),
      }
    ]
  },
  {path: '', redirectTo: 'home', pathMatch: 'full'},
  // {path: '**', redirectTo: '', pathMatch: 'full'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled', anchorScrolling: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
